import React from 'react'
import { message, Statistic } from 'antd'
import moment from 'moment'
import 'moment/locale/id'
import Countdown, { zeroPad } from 'react-countdown'
import parse from 'html-react-parser'

const { Countdown: CD } = Statistic

const toThousand = p => p.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
const dateTimeFormat = (date, format) =>
  moment(date)
    .locale('id')
    .format(format)

const Duration = p => {
  const { date, cb } = p
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) return cb
    return (
      <span style={{ color: '#F15A5A' }} className="font-size-36">
        {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    )
  }
  return <Countdown date={date} renderer={renderer} />
}

const DurationAntd = ({ date, cb }) => {
  return <CD onFinish={cb} value={date} valueStyle={{ color: '#F15A5A', fontSize: 36 }} />
}

const copyToClipBoard = async e => {
  try {
    await navigator.clipboard.writeText(`${e?.target?.value || e}`)
    return message.success('Copied!')
  } catch (error) {
    return message.error(`Failed to copy!, ${error}`)
  }
}

const toHtml = p => parse(p)

export { toThousand, dateTimeFormat, Duration, copyToClipBoard, toHtml, DurationAntd }
